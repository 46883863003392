const DocMenuConfig = [
  {
    pages: [
      {
        heading: "menu.dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "menu.servicios",
    pages: [
      {
        heading: "menu.serviciosIndividuales",
        route: "/listados/serviciosprestados",
        fontIcon: "bi bi-calendar2-check",
      },
      {
        heading: "menu.serviciosProgramados",
        route: "/listados/servicios-programados",
        fontIcon: "bi bi-shop",
      },
    ],
  },
  {
    heading: "menu.planCorresponsables",
    pages: [
      {
        heading: "menu.profesionales",
        route: "/listados/profesionales",
        fontIcon: "bi bi-briefcase",
      },
      {
        heading: "menu.familias",
        route: "/listados/familias",
        fontIcon: "bi bi-house-door",
      },
      {
        heading: "menu.menores",
        route: "/listados/menores",
        fontIcon: "bi bi-emoji-smile",
      },
    ],
  },
  {
    heading: "menu.solicitudes",
    pages: [
      {
        heading: "menu.solicitudesServicios",
        route: "/solicitudes/servicios",
        fontIcon: "bi bi-calendar2-plus",
      },
      {
        heading: "menu.solicitudesProfesionales",
        route: "/solicitudes/profesionales",
        fontIcon: "bi bi-briefcase",
      },
      {
        heading: "menu.solicitudesFamilias",
        route: "/solicitudes/familias",
        fontIcon: "bi bi-house-door",
      },
    ],
  },
  {
    heading: "menu.usuarios",
    pages: [
      {
        heading: "menu.usuariosListado",
        route: "/listados/personal",
        fontIcon: "bi bi-people",
      },
    ],
  },
  {
    heading: "menu.documentacion",
    pages: [
      {
        heading: "menu.manual",
        link: "https://www.loom.com/share/folder/882fb4d96f8a4d668f6c73c2d7bc4b1d",
        fontIcon: "bi bi-life-preserver",
      },
    ],
  },
];

export default DocMenuConfig;
